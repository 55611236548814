    @import "styles/variables";
    @import "styles/mixins";

.mobileCategories {
    @include tablet-up {
        display: none !important;
    }
}

.desktopCategories {
    @include mobile-only {
        display: none !important;
    }
}
