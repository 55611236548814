    @import "styles/variables";
    @import "styles/mixins";

.menu {
    @include mobile-only {
        padding: $spacing-1 0 !important;
        background-color: var(--prezly-background-color-secondary) !important;
    }

    @include tablet-up {
        max-height: 50vh;
        overflow: auto;
    }
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}
