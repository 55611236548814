    @import "styles/variables";
    @import "styles/mixins";

.link {
    @include text-label;

    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: var(--prezly-text-color);
    font-weight: $font-weight-medium;
}

.image {
    margin-bottom: $spacing-1;
    height: auto;
    aspect-ratio: 1.5;

    img {
        border-radius: $border-radius-s;
    }
}
