    @import "styles/variables";
    @import "styles/mixins";

.container {
    @include text-small;

    padding: $spacing-5 0;
    color: var(--prezly-footer-text-color);
    background: var(--prezly-footer-background-color);
}

.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include not-desktop {
        display: block;
    }
}

.links {
    display: flex;
    align-items: center;

    @include not-desktop {
        &:not(:last-child) {
            margin-bottom: $spacing-4;
        }
    }
}

.link {
    display: block;
    padding: 0;
    appearance: none;
    background: none;
    border: 0;
    cursor: pointer;
    color: var(--prezly-footer-text-color);
    text-decoration: underline;

    & + & {
        margin-left: $spacing-3;
    }
}
