    @import "styles/variables";
    @import "styles/mixins";

.wrapper {
    @include shadow-l;

    text-decoration: none;
    padding: $spacing-1 ($spacing-1 + $spacing-half);
    display: flex;
    align-items: center;
    gap: $spacing-1;
    border-radius: $border-radius-m;
    border: 1px solid $color-base-300;
    background: $color-base-white;
    z-index: 20;
}

.logo {
    height: 1.125rem;
    color: $color-base-900;
}

.text {
    @include text-label;

    font-weight: $font-weight-medium;
    color: $color-base-900;
}
