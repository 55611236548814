    @import "styles/variables";
    @import "styles/mixins";

.title {
    display: block;
    text-transform: capitalize;
    color: var(--prezly-text-color);
    font-weight: $font-weight-medium;
    text-decoration: none;
}
