    @import "styles/variables";
    @import "styles/mixins";

$header-height: 88px;

.container {
    display: flex;
    background: var(--prezly-header-background-color);
    min-height: $header-height;
    z-index: 2;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $spacing-3 0;
    height: 100%;

    .navigationToggle {
        color: var(--prezly-header-link-color);
        font-size: $font-size-m;
        line-height: 1;
        padding: $spacing-2;
        margin-right: -1 * $spacing-2;

        @include tablet-up {
            display: none;
        }
    }

    .navigationButton {
        color: var(--prezly-header-link-color);

        @include mobile-only {
            @include text-label;

            display: flex;
            text-align: left;
            justify-content: flex-start;
            padding: $spacing-4 $grid-gutter-mobile;
            color: var(--prezly-header-link-color);
            border-top: 1px solid var(--prezly-border-color);
        }

        &:hover,
        &:active,
        &:focus {
            opacity: 1 !important;
        }

        &:active {
            border-color: transparent !important;
        }
    }

    .searchToggle {
        color: var(--prezly-header-link-color);

        @include mobile-only {
            font-size: $font-size-m;
            line-height: 1;
            padding: $spacing-2;
        }

        @include tablet-up {
            order: 10;
        }

        &:active {
            border-color: transparent !important;
        }

        svg {
            width: 1.125rem;
            height: 1.125rem;
        }
    }

    .searchToggle.close {
        @include mobile-only {
            margin-right: -1 * $spacing-2;
        }
    }

    .navigationToggle.hidden,
    .searchToggle.hidden {
        @include mobile-only {
            display: none;
        }
    }
}

.newsroom {
    @include link-primary;

    display: flex;

    &.withoutLogo {
        color: var(--prezly-header-link-color);

        &:hover {
            color: var(--prezly-header-link-color);
            opacity: 0.8;
        }
    }
}

.title {
    font-size: 1.75rem;
    line-height: 2.5rem;
    text-transform: uppercase;
    color: inherit;

    @include not-desktop {
        font-size: 1.25rem;
    }

    &.hidden {
        @include sr-only;
    }
}

.navigation,
.navigationInner {
    @include tablet-up {
        display: flex;
        align-items: center;
    }
}

.navigationWrapper {
    display: flex;
    align-items: center;
    margin-left: auto;
}

/* Since search widget is in a portal, there is no better way of increasing the specificity other than adding global selectors */
/* stylelint-disable selector-no-qualifying-type */
.navigation,
div.mobileSearchWrapper {
    @include mobile-only {
        position: fixed;
        inset: $header-height 0 0 0;
        overflow: hidden;
    }
}
/* stylelint-enable selector-no-qualifying-type */

.navigation {
    @include mobile-only {
        &:not(.open) {
            pointer-events: none;
        }
    }
}

.navigationInner {
    list-style: none;
    margin: 0;
    padding: 0;

    @include mobile-only {
        position: relative;
        background: var(--prezly-header-background-color);
        transform: translateY(-50%);
        opacity: 0;
        transition: transform 0.3s ease, opacity 0.3s ease;
        max-height: 100%;
        border-bottom: 1px solid var(--prezly-border-color);
        overflow-y: auto;

        .open & {
            transform: translateY(0);
            opacity: 1;
        }
    }
}

.navigationItem {
    display: block;
    margin: 0;
    padding: 0;
}

.backdrop {
    position: absolute;
    inset: 0;
    background-color: $color-base-900;
    opacity: 0;
    transition: opacity 0.3s ease;

    @include mobile-only {
        .open & {
            opacity: 0.5;
        }
    }

    @include tablet-up {
        display: none;
    }
}

/* This class is added to the document body to prevent scrolling */
.body {
    overflow: hidden;
}
